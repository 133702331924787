@tailwind base;
@tailwind components;
@tailwind utilities;

.fade-in {
  animation: fadeIn 3s ease-in infinite;
}

.fade-out {
  animation: fadeOut 3s ease-out infinite;
}

@keyframes fadeIn {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeOut {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}
